<template>
  <div>
    <el-dialog :title="$t('common.certificateList')" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
      <el-table :data="data" v-loading="loading" ref="singleTable" highlight-current-row @current-change="handleCurrentChange" height="450">
        <el-table-column property="cylinderNumber" :label="$t('label.certificateList')"></el-table-column>
        <el-table-column property="batchNumber" label="批次编号"></el-table-column>
        <el-table-column property="orderNo" :label="$t('label.Order_number')"></el-table-column>
        <el-table-column property="skuName" :label="$t('label.Trade_name')"></el-table-column>
        <el-table-column :label="$t('label.Controls')">
          <template slot-scope="scope">
            <div style="display: flex;">
            <el-button type="primary" size="small" v-if="scope.row.certId!=null" @click="downloadCert(scope.row.certId,scope.row.cylinderNumber)">{{ $t('button.Download_certificate') }}
            </el-button>
            <el-button type="success" size="small" v-if="scope.row.batchNumber!=null" @click="showQualityInfo(scope.row)">出厂信息
            </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false" size="mini">{{ $t('button.Off') }}</el-button>
      </div>
    </el-dialog>
    <quality_list ref="qualityList" />
  </div>
</template>

<script>
  import {
    post,
    get,
    getextra,
    downloadFile
  } from '@/utils/request';
  import quality_list from './quality-list';
  export default {
     components: {
      quality_list
    },
    name: 'cert-list',
    data() {
      return {
        dialogTableVisible: false,
        loading: true,
        data: null,
        skuBasic: {
          skuName: null
        },
        orderId:null,
        orderNo: null,
        currentRow: null,
        orderSkuId: 0,
      };
    },
    methods: {
      initData() {
        // get('api/cert/getOrderCertListByOrderId?orderSkuId=' + this.orderSkuId).then((res) => {
        //   this.data = res.data.data.orderCertList;
        //   this.skuBasic = res.data.data.orderSku;
        // })

          get('api/syncData/orderQualityList?orderId=' + this.orderId + '').then((res) => {
             this.data = res.data.data
             this.loading=false
          })
      },
      handleCurrentChange(val) {
        this.currentRow = val;
      },
      // 下载证书
      downloadCert(certId, cylinderNumber) {
        getextra('api/cert/downloadCert?certId=' + certId, {}, {
          responseType: 'blob'
        }).then((res) => {
          let url = window.URL.createObjectURL(new Blob([res]))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', this.parseTime(new Date()) + '-' + cylinderNumber + '.pdf')
          // +'.pdf' 通过不同的后缀名下载不同的文件
          //这里下载的是pdf格式的文件，拿到接口里的res也必须是pdf的文件流
          document.body.appendChild(link)
          link.click()
        })
      },
      // 出厂信息
      showQualityInfo(item){
        // 打开出厂信息画面
        this.isLoading = false;
        this.$refs.qualityList.batchNumber = item.batchNumber;
        this.$refs.qualityList.orderNo = item.orderNo;
        this.$refs.qualityList.initData();
        this.$refs.qualityList.dialogTableVisible = true;
        this.$refs.qualityList.loading = true;
      },
      /**
       * Parse the time to string
       * @param {(Object|string|number)} time
       * @param {string} cFormat
       * @returns {string}
       */
      parseTime(time, cFormat) {
        if (arguments.length === 0) {
          return null
        }
        const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'undefined' || time === null || time === 'null') {
          return ''
        } else if (typeof time === 'object') {
          date = time
        } else {
          if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
          }
          if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
          }
          date = new Date(time)
        }
        const formatObj = {
          y: date.getFullYear(),
          m: date.getMonth() + 1,
          d: date.getDate(),
          h: date.getHours(),
          i: date.getMinutes(),
          s: date.getSeconds(),
          a: date.getDay()
        }
        const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
          let value = formatObj[key]
          // Note: getDay() returns 0 on Sunday
          if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
          }
          if (result.length > 0 && value < 10) {
            value = '0' + value
          }
          return value || 0
        })
        return time_str
      }
    },
  };
</script>

<style scoped></style>